import app from 'firebase/app'
import 'firebase/database'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
}

class Firebase {
  constructor () {
    app.initializeApp(config)
    this.db = app.database()
    console.log('Firebase init!')
  }

  createPoll = (poll) => {
    return this.db.ref('/polls').push(poll)
  }

  getPoll = (pollId) => {
    return this.db.ref(`/polls/${pollId}`).once('value')
  }

  postFormAnswers = (pollId, answers) => {
    return this.db.ref(`/answerPolls/${pollId}/answers/times`).push(answers)
  }
  postFormAnswersNoTimesWork = (pollId, answers) => {
    return this.db.ref(`/answerPolls/${pollId}/answers/noTimes`).push(answers);
  }
  createAnswerPoll = (data) => {
    return this.db.ref('/answerPolls').push(data);
  }

  updatePoll = (pollId, data) => {
    return this.db.ref(`/polls/${pollId}`).update(data)
  }

  getAnswerPoll = (pollId) => {
    return this.db.ref(`/answerPolls/${pollId}/`).once('value');
  }

  pollAnswerListener = (answerPollId, callback) => {
    return this.db.ref(`/answerPolls/${answerPollId}/answers`).on('value', callback);
  }
}

const firebase = new Firebase()

export default firebase
