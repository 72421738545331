import { Time } from './Time';

class Poll {
  title;
  timeRange;

  constructor(value) {
    this.title = value.title;
    this.timeRange = [new Time(value.timeRange[0]), new Time(value.timeRange[1])] 
      || [new Time(0), new Time(1)];
  }
}

export class SingleDatePoll extends Poll {
  date;

  constructor(value) {
    console.log(value);
    super(value);
    this.date = value.date;
  }

  toObject = () => {
    return {
      title: this.title,
      timeRange: this.timeRange.map((timeObj) => timeObj.toString()),
      date: this.date
    }
  }
}

export const POLL_TYPE = {
  SINGLE_DATE: "SINGLE_DATE",
  DATE_RANGE: "DATE_RANGE",
  DAYS_OF_WEEK: "DAYS_OF_WEEK"
}

