import React, { useState } from 'react'
import {
  FormControl,
  FormLabel,
  Select,
  SimpleGrid
} from '@chakra-ui/react'
import { Time } from '../utilities/Time';

const TimeRange = (props) => {
  const { formHook, watch } = props;
  
  const watchStartTime = watch("startTime");

  const getStartTimeOptions = () => {

    /* Slice to remove last time, to prevent start range from === end range */
    return Time.getTime24h().slice(0, -1).map((time) => {
      return (
        <option key={`start${time.toString()}`} value={time.toString()}>{time.toString()}</option>
      );
    })
  }

  const getEndTimeOptions = (startTime) => {
    return Time.getTimesGreaterThan(new Time(startTime)).map((time) => (
      <option key={`start${time.toString()}`} value={time.toString()}>{time.toString()}</option>
    ))
  }

  return (
    <div className='TimeRange'>
      <FormControl>
        <FormLabel>Time Range</FormLabel>
        <SimpleGrid columns={2} spacing={10}>
          <Select name="startTime" id='startTime' ref={formHook()}>
            {/* {createOptions('Start')} */}
            {getStartTimeOptions()}
          </Select>
          <Select name="endTime" id='endTime'  ref={formHook()}>
            {getEndTimeOptions(watchStartTime)}
          </Select>
        </SimpleGrid>
      </FormControl>
    </div>
  )
}

export { TimeRange }
