import React from "react";
import { useForm } from "react-hook-form";

import {
  Center,
  Box,
  Heading,
  Text,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ScaleFade,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormErrorMessage,
} from "@chakra-ui/react";
import Firebase from "../utilities/firebase";
import { SingleDay } from "./SingleDay";
import { DaysOfTheWeek } from "./DaysOfTheWeek";
import { DateRange } from "./DateRange";
import { TimeRange } from "./TimeRange";
import { Time } from "../utilities/Time";
import { SingleDatePoll } from "../utilities/Poll";
import { useHistory } from "react-router-dom";

const CreatePoll = () => {
  const history = useHistory();

  const { handleSubmit, errors, register, formState, watch } = useForm({
    defaultValues: {
      startTime: Time.getTime24h[0],
    },
  });


  const onSubmit = async (values) => {

    /* Create a poll */
    const result = await Firebase.createPoll(
      new SingleDatePoll({
        title: values.title,
        timeRange: [values.startTime, values.endTime],
        date: values.singleDate,
      }).toObject()
    );
    
    /* Get reference to the newly created poll */
    const pollId = result.key;
    
    const answerPollId = (await Firebase.createAnswerPoll({ masterPollId: pollId })).key;

    await Firebase.updatePoll(pollId, { answerPollId });
    /* Navigate to the View Poll page */
    history.push(`/view/${pollId}`)
  };

  const validateTitle = (value) => {
    if (!value) {
      return "Title is required";
    } else {
      return true;
    }
  };

  const validateSingleDate = (value) => {
    if (!value) {
      return "Please choose a date";
    } else {
      return true;
    }
  };

  const today = new Date();
  today.setDate(today.getDate() - 1);
  const todayValue = today.toISOString().substr(0, 10);

  return (
    <div className="CreatePoll">
      <ScaleFade in={true} initialScale={0.9}>
        <Center w="100%" px="2rem" h="100vh">
          <Box w="100%" maxW="30rem">
            <Heading fontSize="3xl">Create Poll</Heading>
            <Text mb={6} color="gray.500" fontSize="md">
              Select date and time.
            </Text>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="1rem">
                <FormControl isInvalid={errors.title}>
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Input
                    name="title"
                    placeholder="Enter title"
                    ref={register({ validate: validateTitle })}
                  />
                  <FormErrorMessage>
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>

                <Tabs
                  size="sm"
                  colorScheme="purple"
                  isFitted
                  variant="enclosed"
                  isLazy
                >
                  <TabList>
                    <Tab>Single Day</Tab>
                    <Tab>Date Range</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel className="custom-tabs">
                      <Stack spacing="1rem">
                        <FormControl id="date" isInvalid={errors.singleDate}>
                          <FormLabel>Date</FormLabel>
                          <Input
                            name="singleDate"
                            min={todayValue}
                            type="date"
                            ref={register({ validate: validateSingleDate })}
                          />
                          <FormErrorMessage>
                            {errors.singleDate && errors.singleDate.message}
                          </FormErrorMessage>
                        </FormControl>
                        <TimeRange watch={watch} formHook={register} />
                      </Stack>
                    </TabPanel>

                    {/* ***** DATE RANGE **** */}
                    <TabPanel className="custom-tabs">
                      <Text>This is WIP right now.</Text>
                      {/* <Stack spacing="1rem">
                        <FormControl id="date" isInvalid={errors.singleDate}>
                          <FormLabel>Date</FormLabel>
                          <Input
                            name="dateRange"
                            min={todayValue}
                            type="date"
                            ref={register({ validate: validateSingleDate })}
                          />
                          <FormErrorMessage>
                            {errors.singleDate && errors.singleDate.message}
                          </FormErrorMessage>
                        </FormControl>
                        <TimeRange formState={formState} formHook={register} />
                      </Stack> */}
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                {/* <Tabs
                    size="sm"
                    colorScheme="purple"
                    isFitted
                    variant="enclosed"
                  >
                    <TabList>
                      <Tab>Single Day</Tab>
                      <Tab>Date Range</Tab>
                      <Tab>Days of the Week</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel className="custom-tabs">
                        <SingleDay />
                      </TabPanel>
                      <TabPanel className="custom-tabs">
                        <DateRange />
                      </TabPanel>
                      <TabPanel className="custom-tabs">
                        <DaysOfTheWeek />
                      </TabPanel>
                    </TabPanels>
                  </Tabs> */}
              </Stack>
              <Button
                // onClick={onSubmitPoll}
                w="100%"
                isLoading={formState.isSubmitting}
                type="submit"
                mt={8}
                colorScheme="purple"
              >
                Create
              </Button>
            </form>
          </Box>
        </Center>
      </ScaleFade>
    </div>
  );
};

export { CreatePoll };
