import React from 'react';
import {
  Grid, Button, Box, Heading, Text, ScaleFade
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();

  return (
    <ScaleFade in={true} initialScale={0.9}>
    <Grid h="100vh" w="100vw" placeItems="center">
      <Box maxW="30rem" w="100%" px="2rem">
        <Heading fontSize="3xl">Thanks For Answering!</Heading>
        <Text mb={6} color="gray.500" fontSize="md">
              Your answer is appreciated. 
            </Text>

            <Button mt={4} colorScheme="purple" w="100%" onClick={() => history.push("/")}>Go Home</Button>
      </Box>

    </Grid>
    </ScaleFade>
  )
}