import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Center,
  Spinner,
  ScaleFade,
  Heading,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Grid,
  GridItem,
  Text,
  Flex,
  FormControl,
  Input,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { CalendarIcon, TimeIcon, ArrowBackIcon } from "@chakra-ui/icons";
import Firebase from "../utilities/firebase";
import { Time } from "../utilities/Time";

const NO_TIMES_WORK = "NO_TIMES_WORK";

export default () => {
  
  /* Get the poll id from the URL */
  const { answerPollId } = useParams();

  /* Get history to change current page */
  const history = useHistory();

  /* Poll object form is using */
  const [poll, setPoll] = useState({ timeRange: {} });

  /* Loading indicator */
  const [isLoading, setIsLoading] = useState(true);

  /* Handle errors when getting poll data */
  const [hasErrorLoadingPoll, setHasErrorLoadingPoll] = useState(false);

  /* Handle user selected times */
  const [selectedTimes, setSelectedTimes] = useState([]);

  /* Handle showing continue screen */
  const [showContinueScreen, setShowContinueScreen] = useState(false);

  /* Handle name field */
  const [name, setName] = useState("");

  const [isAnswersSubmitting, setIsAnswersSubmitting] = useState(false);

  useEffect(() => {
    async function fetchPoll() {
      try {
        const answerPollRef = await Firebase.getAnswerPoll(answerPollId);
        const answerPoll = await answerPollRef.val();
        console.log(answerPoll);
        if (answerPoll === null) {
          throw Error("Could not find poll");
        }
        const masterPollId = answerPoll.masterPollId;

        const pollRef = Firebase.getPoll(masterPollId);
        const poll = (await pollRef).val();
        if (poll === null) {
          throw Error("Could not find poll");
        }
        console.log(poll);

        /* Clear loading indicator */
        setIsLoading(false);
        setPoll(poll);
      } catch (error) {
        setHasErrorLoadingPoll(true);
      }
    }

    fetchPoll();
  }, [answerPollId]);

  /* Toggle selected times */
  const onTimeOptionClickWrapper = (time) => {
    const onTimeOptionClick = () => {
      const index = selectedTimes.indexOf(time.toString());
      if (index < 0) {
        setSelectedTimes((oldState) => [...oldState, time]);
      } else {
        setSelectedTimes(
          selectedTimes.filter((item) => item.toString() !== time.toString())
        );
      }
    };

    return onTimeOptionClick;
  };

  const renderTimeOptions = () => {
    const startTime = new Time(poll.timeRange[0]);
    const endTime = new Time(poll.timeRange[1]);
    return Time.getTimesBetweenTimeRange(startTime, endTime).map((time) => {
      const selected = selectedTimes.indexOf(time.toString()) > -1;
      const timeObject = new Time(time.toString());
   
      return (
        <GridItem
          borderRadius="md"
          w="100%"
          flexDir="column"
          d="flex"
          justifyContent="center"
          alignItems="center"
          h="100%"
          fontSize={{
            base: "md",
            sm: "md",
            md: "xl",
          }}
          transition="background 250ms ease"
          bg={selected ? "purple.500" : "#fff"}
          color={selected ? "#fff" : null}
          cursor="pointer"
          _hover={selected ? null : { background: "purple.100" }}
          onClick={onTimeOptionClickWrapper(time)}
        >
          <span>{time.toString()}-</span>
        
          <Text fontSize="sm" color={selected ? "gray.300" : "gray.500"}>{new Time(timeObject.index + 1).string}</Text>
        </GridItem>
      );
    });
  };

  const onContinueClick = () => {
    setShowContinueScreen(true);
  };

  const handleNoTimesWorks = async () => {
    await Firebase.postFormAnswersNoTimesWork(answerPollId, {
      name: name ? name : "Anonymous",
      answers: selectedTimes
    });

    setTimeout(() => {
      setIsAnswersSubmitting(false);
      history.push("/thank-you")
    }, 1500)
  }

  const onAnswersSubmit = async () => {

    if (selectedTimes[0] === NO_TIMES_WORK) {
      await handleNoTimesWorks();
      return;
    }

    const convertedTimes = selectedTimes.map(time => new Time(time))

    const sortedAnswers = convertedTimes.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      } else if (a.index < b.index) {
        return -1;
      }

      return 0;
    })

    const formattedAnswers = sortedAnswers.map((time) => time.toString());
    setIsAnswersSubmitting(true);

    await Firebase.postFormAnswers(answerPollId, {
      name: name ? name : "Anonymous",
      answers: formattedAnswers
    });
    
    setTimeout(() => {
      setIsAnswersSubmitting(false);
      history.push("/thank-you")
    }, 1500)
   
  }

  

  const renderMainPage = () => {
    const formRender = (
      <ScaleFade in={true} initialScale={0.9}>
        <Grid h="100vh" w="100vw" placeItems="center">
          <Modal
            isOpen={showContinueScreen}
            onClose={() => setShowContinueScreen(false)}
            isCentered
           
          >
            <ModalOverlay />
            <ModalContent mx="2rem">
              <ModalHeader>Submit Answers</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel htmlFor="name">Name (optional)</FormLabel>
                  <Input id="name" onChange={e => setName(e.target.value)} value={name} placeholder="Enter name" />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  isLoading={isAnswersSubmitting}
                  colorScheme="purple"
                  mr={3}
                  onClick={onAnswersSubmit}
                >
                  Submit
                </Button>
                <Button variant="ghost" disabled={isAnswersSubmitting} onClick={() => {setShowContinueScreen(false); setSelectedTimes([])}}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Grid
            gridTemplateRows="auto minmax(0, 1fr) auto"
            gridTemplateColumns="1fr"
            h="100vh"
            w="100%"
            maxW="80rem"
            p="2rem"
            gridGap="1rem"
          >
            <GridItem>
              <Box d="flex" alignItems="baseline">
                <Heading
                  fontSize={{
                    base: "lg",
                    sm: "xl",
                    md: "2xl",
                    lg: "3xl",
                  }}
                  lineHeight={{ base: 1.2, sm: "auto" }}
                  mb="0.5rem"
                >
                  What time are you free for...
                </Heading>
              </Box>
              <Text
                fontSize={{
                  base: "lg",
                  sm: "xl",
                  md: "xl",
                  lg: "2xl",
                }}
              >
                {poll.title}
              </Text>
              <Flex mt="0.25rem" flexWrap="wrap" alignItems="center">
                <CalendarIcon mr="0.5rem" color="gray.500" />
                <Text
                  fontSize={{
                    base: "sm",
                    sm: "md",
                    md: "lg",
                  }}
                  color="gray.500"
                  mr="1rem"
                >
                  {poll.date}
                </Text>
                <TimeIcon mr="0.5rem" color="gray.500" />
                <Text
                  fontSize={{
                    base: "sm",
                    sm: "md",
                    md: "lg",
                  }}
                  color="gray.500"
                >
                  {poll.timeRange[0]}
                </Text>
                <Text fontSize="sm" color="gray.500" mx="0.5rem">
                  -
                </Text>
                <Text
                  fontSize={{
                    base: "sm",
                    sm: "md",
                    md: "lg",
                  }}
                  color="gray.500"
                >
                  {poll.timeRange[1]}
                </Text>
              </Flex>
            </GridItem>
            <GridItem bg="gray.100" borderRadius="lg" h="100%" overflowY="auto">
              <Grid
                h="100%"
                w="100%"
                gridTemplateColumns={{
                  base: "repeat(2, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "repeat(4, 1fr)",
                }}
                gridAutoRows={{
                  base: "minmax(0, calc((100% - 3rem) / 5))",
                  sm: "minmax(0, calc((100% - 5rem) / 6))",
                  md: "minmax(0, calc((100% - 3rem) / 4))",
                  xl: "minmax(0, calc((100% - 3rem) / 5))",
                }}
                gridGap="1rem"
                padding="1rem"
                columnGap="1rem"
                userSelect="none"
              >
                {renderTimeOptions()}
                <GridItem></GridItem>
              </Grid>
            </GridItem>
            <Flex alignItems="center" justifyContent="space-between">
              <Button onClick={() => { setSelectedTimes([NO_TIMES_WORK]); onContinueClick() }}>No Times Work</Button>
              <Button
                colorScheme="purple"
                disabled={selectedTimes.length === 0}
                onClick={onContinueClick}
              >
                Continue
              </Button>
            </Flex>
            <GridItem></GridItem>
          </Grid>
        </Grid>
      </ScaleFade>
    );

    const continueScreen = (
      <ScaleFade in={true} initialScale={0.9}>
        <Grid h="100vh" w="100vw" placeItems="center">
          <Box px="2rem" w="100%" maxW="30rem">
            <Heading fontSize="3xl">Submit Answers</Heading>
            <Text mb={6} color="gray.500" fontSize="md">
              Optionally enter your name.
            </Text>
            <FormControl>
              <FormLabel htmlFor="name">Name (optional)</FormLabel>
              <Input name="name" placeholder="Enter Name" />
            </FormControl>
            <Button mt={8} colorScheme="purple" w="100%">
              Submit
            </Button>
            <Button leftIcon={<ArrowBackIcon />} mt="1rem" w="100%">
              Go Back
            </Button>
          </Box>
        </Grid>
      </ScaleFade>
    );

    return formRender;
  };

  return (
    <>
      {hasErrorLoadingPoll ? (
        <Box>
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              Error! Could not find the specified poll.
            </AlertDescription>
          </Alert>

          <Box p="1rem">
            <Button onClick={() => history.push("/")} colorScheme="purple">
              Return home
            </Button>
          </Box>
        </Box>
      ) : isLoading ? (
        <ScaleFade in={true} initialScale={0.9}>
          <Center width="100%" height="100vh">
            <Box textAlign="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="purple.500"
                size="xl"
              />
              <Heading color="gray.500" fontSize="xl">
                Harmonize
              </Heading>
            </Box>
          </Center>
        </ScaleFade>
      ) : (
        renderMainPage()
      )}
    </>
  );
};
