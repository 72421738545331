import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Center,
  Spinner,
  ScaleFade,
  Heading,
  Box,
  AlertIcon,
  AlertDescription,
  Button,
  GridItem,
  Grid,
  Text,
  Alert,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Link,
  Input,
  InputGroup,
  InputLeftElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  ListItem,
  UnorderedList,
  list,
} from "@chakra-ui/react";

import {
  CalendarIcon,
  TimeIcon,
  ArrowBackIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { Bar } from "react-chartjs-2";
import Firebase from "../utilities/firebase";
import { Time } from "../utilities/Time";

const data = {
  labels: [
    "10:00 AM",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "May",
    "June",
    "July",
    "May",
    "June",
    "July",
  ],
  datasets: [
    {
      label: "Poll Answers",
      backgroundColor: "rgba(128, 90, 213, 0.2)",
      borderColor: "rgba(128, 90, 213, 1.0)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(128, 90, 213, 0.4)",
      hoverBorderColor: "rgba(128, 90, 213, 1.0)",
      data: [65, 59, 80, 81, 56, 55, 40, 3, 3, 3, 3, 3, 3],
    },
  ],
};

export default () => {
  const { pollId } = useParams();
  const history = useHistory();
  const [poll, setPoll] = useState({ timeRange: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrorLoadingPoll, setHasErrorLoadingPoll] = useState(false);
  const [chartJsData, setChartJsData] = useState({});
  const [pollData, setPollData] = useState([]);
  const [listDataObject, setListDataObject] = useState({});
  const [mostSelectedTime, setMostSelectedTime] = useState({
    time: "N/A",
    count: 0,
  });

  const [countNoTimesWork, setCountNoTimesWork] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    let listener;

    async function fetchPoll() {
      try {
        const pollRef = await Firebase.getPoll(pollId);
        const poll = await pollRef.val();

        if (poll === null) {
          throw Error("Could not find poll");
        }

        /* Clear loading indicator */
        setIsLoading(false);

        // const answerPollRef = await Firebase.getAnswerPoll(poll.answerPollId);
        // const answerPoll = answerPollRef.val();

        // calculateData(poll, answerPoll);

        listener = Firebase.pollAnswerListener(
          poll.answerPollId,
          async (value) => {
            if (value === undefined || value === null) {
              setPoll(poll);
              return;
            }

            console.log("I AM CALLED!");
            const answerPollRef = value;
            const answerPoll = await answerPollRef.val();

            if (answerPoll === null) {
              setPoll(poll);
              calculateData(poll, {}, {});
              return;
            }
            calculateData(
              poll,
              { answers: answerPoll.times },
              answerPoll.noTimes
            );
            setPoll(poll);
          }
        );
      } catch (error) {
        setHasErrorLoadingPoll(true);
      }
    }

    fetchPoll();

    return () => {};
  }, []);

  /* Transform data into something usable */
  const calculateData = (poll, answerPoll, noTimes) => {
    const dataObject = {};
    const startIndex = new Time(poll.timeRange[0]).index;

    const timeRange = Time.getTimesBetweenTimeRange(
      new Time(poll.timeRange[0]),
      new Time(poll.timeRange[1])
    );
    dataObject["labels"] = timeRange;
    dataObject["labels"].push("No Times Work");

    const dataset = {
      label: "people",
      backgroundColor: "rgba(128, 90, 213, 0.2)",
      borderColor: "rgba(128, 90, 213, 1.0)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(128, 90, 213, 0.4)",
      hoverBorderColor: "rgba(128, 90, 213, 1.0)",
      data: [],
    };

    /* Create an array of data */
    const data = timeRange.map((time) => 0);
    data.push(0);
    const pollData = [];
    const listData = {};

    timeRange.forEach((time) => {
      listData[time] = [];
    });

    if ((!answerPoll, !answerPoll.answers && !noTimes)) {
      setChartJsData(dataObject);
      setPollData(pollData);

      setListDataObject(listData);
      return;
    }

    if (answerPoll.answers) {
      Object.keys(answerPoll.answers).forEach((key) => {
        const answerObject = answerPoll.answers[key];

        answerObject.answers.forEach((time) => {
          const timeObject = new Time(time);

          if (!listData[time]) {
            listData[time] = [];
          }

          listData[time].push(answerObject.name);
          const index = timeObject.index - startIndex;
          data[index]++;
        });

        pollData.push({
          name: answerObject.name,
          answers: answerObject.answers,
        });
      });
    }

    if (noTimes) {
      Object.keys(noTimes).forEach((key) => {
        const obj = noTimes[key];
        if (!listData["No Times Work"]) {
          listData["No Times Work"] = [];
        }

        listData["No Times Work"].push(obj.name);

        data[data.length - 2]++;
        pollData.push({ name: obj.name, answers: obj.answers });
      });

      setCountNoTimesWork(Object.keys(noTimes).length);
    }

    dataset.data = data;

    dataObject["datasets"] = [dataset];

    let max = -1;
    let maxIndex = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i] === "NO_TIMES_WORK") continue;

      if (data[i] > max) {
        max = data[i];
        maxIndex = i;
      }
    }

    /* Handle no times */

    if (max !== -1) {
      setMostSelectedTime({
        time: new Time(startIndex + maxIndex).toString(),
        count: data[maxIndex],
      });
    }

    setChartJsData(dataObject);
    setPollData(pollData);
    setListDataObject(listData);
  };

  const renderListData = () => {
    return Object.keys(listDataObject).map((time) => {
      return (
        <AccordionItem key={time}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="semibold">{time}</Text>
                <Text color="gray.500">{`${listDataObject[time].length} people`}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList>
              {listDataObject[time].length > 0 ? (
                listDataObject[time].sort().map((name) => {
                  return <ListItem key={`${time}${name}`}>{name}</ListItem>;
                })
              ) : (
                <Text mt={4}>No people have chosen this time.</Text>
              )}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      );
    });
  };

  const renderSearchData = () => {
    let dataToUse;
    if (searchQuery) {
      dataToUse = pollData.filter((answer) => {
        return (
          answer.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
        );
      });
    } else {
      dataToUse = pollData;
    }

    return dataToUse.map((answer) => {
      return (
        <AccordionItem key={answer.name}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text fontWeight="semibold">{answer.name}</Text>
                <Text color="gray.500">{`${answer.answers.length} times`}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text fontWeight="semibold">Selected Times:</Text>
            <UnorderedList>
              {answer.answers.length > 0 ? (
                answer.answers.map((time, i) => {
                  return (
                    <ListItem key={`${time}${answer.name}${i}`}>
                      {time}
                    </ListItem>
                  );
                })
              ) : (
                <Text mt={4}>This person has not chosen any times.</Text>
              )}
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      );
    });
  };

  return (
    <div className="AnswerPoll">
      {hasErrorLoadingPoll ? (
        <Box>
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>
              Error! Could not find the specified poll.
            </AlertDescription>
          </Alert>

          <Box p="1rem">
            <Button onClick={() => history.push("/")} colorScheme="purple">
              Return home
            </Button>
          </Box>
        </Box>
      ) : isLoading ? (
        <ScaleFade in={true} initialScale={0.9}>
          <Center width="100%" height="100vh">
            <Box textAlign="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="purple.500"
                size="xl"
              />
              <Heading color="gray.500" fontSize="xl">
                Harmonize
              </Heading>
            </Box>
          </Center>
        </ScaleFade>
      ) : (
        <ScaleFade in={true} initialScale={0.9}>
          <Grid
            w="100vw"
            minH="100vh"
            bg="gray.100"
            justifyItems="center"
            gridTemplateColumns="minmax(0, 1fr)"
            gridTemplateRows="minmax(0, 1fr)"
          >
            <Grid
              gridTemplateRows="auto minmax(0, 1fr)"
              gridTemplateColumns="minmax(0, 1fr)"
              maxWidth="70rem"
              px="2rem"
              bg="#fff"
              w="100%"
            >
              <GridItem>
                <Heading
                  pt="2rem"
                  fontSize={{
                    base: "lg",
                    sm: "xl",
                    md: "2xl",
                    lg: "3xl",
                  }}
                  lineHeight={{ base: 1.2, sm: "auto" }}
                  mb="0.5rem"
                >
                  View Results
                </Heading>
                <Text
                  fontSize={{
                    base: "lg",
                    sm: "xl",
                    md: "xl",
                    lg: "2xl",
                  }}
                >
                  {poll.title}
                </Text>
                <Flex mt="0.25rem" flexWrap="wrap" alignItems="center">
                  <CalendarIcon mr="0.5rem" color="gray.500" />
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "md",
                      md: "lg",
                    }}
                    color="gray.500"
                    mr="1rem"
                  >
                    {poll.date}
                  </Text>
                  <TimeIcon mr="0.5rem" color="gray.500" />
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "md",
                      md: "lg",
                    }}
                    color="gray.500"
                  >
                    {poll.timeRange[0]}
                  </Text>
                  <Text fontSize="sm" color="gray.500" mx="0.5rem">
                    -
                  </Text>
                  <Text
                    fontSize={{
                      base: "sm",
                      sm: "md",
                      md: "lg",
                    }}
                    color="gray.500"
                  >
                    {poll.timeRange[1]}
                  </Text>
                </Flex>

                <Flex
                  flexWrap="wrap"
                  w="100%"
                  bg="purple.200"
                  color="purple.700"
                  borderLeftWidth="4px"
                  borderLeftColor="purple.700"
                  mt="1rem"
                  px="1rem"
                  alignItems="baseline"
                  py="0.5rem"
                >
                  <Text mr="1rem">Share this link:</Text>
                  <Link
                    fontSize="sm"
                    href={`https://${window.location.hostname}/answer/${poll.answerPollId}`}
                    textDecoration="underline"
                    isExternal
                  >{`https://${window.location.hostname}/answer/${poll.answerPollId}`}</Link>
                </Flex>
              </GridItem>

              <GridItem w="100%">
                <Tabs
                  mt="2rem"
                  variant="soft-rounded"
                  colorScheme="purple"
                  isLazy
                  size="md"
                >
                  <TabList mb="1rem">
                    <Tab>Stats</Tab>
                    <Tab>Bar</Tab>
                    <Tab>Search</Tab>
                    <Tab>List</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Grid
                        p="1rem"
                        w="100%"
                        h="100%"
                        borderRadius="md"
                        bg="gray.100"
                        columnGap="1rem"
                        rowGap="1rem"
                        gridTemplateColumns={{
                          base: "1fr",
                          sm: "1fr 1fr",
                          md: "1fr 1fr",
                          lg: "1fr 1fr 1fr",
                        }}
                      >
                        <GridItem bg="#fff" p="1rem" borderRadius="md">
                          <Stat
                            colorScheme="purple"
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <StatLabel>Number of Answers</StatLabel>
                            <StatNumber>{pollData.length}</StatNumber>
                            <StatHelpText>people</StatHelpText>
                          </Stat>
                        </GridItem>

                        <GridItem bg="#fff" p="1rem" borderRadius="md">
                          <Stat
                            colorScheme="purple"
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <StatLabel>Most Selected Time</StatLabel>
                            <StatNumber>{mostSelectedTime.time}</StatNumber>
                            <StatHelpText>
                              {`${mostSelectedTime.count} person`}
                            </StatHelpText>
                          </Stat>
                        </GridItem>

                        <GridItem bg="#fff" p="1rem" borderRadius="md">
                          <Stat
                            colorScheme="purple"
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <StatLabel>No Times Work</StatLabel>
                      <StatNumber>{countNoTimesWork}</StatNumber>
                            <StatHelpText>people can't attend</StatHelpText>
                          </Stat>
                        </GridItem>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Box w="100%" overflowX="scroll">
                        <Box
                          h="20rem"
                          position="relative"
                          width={{
                            base: 60 * data.labels.length,
                            sm: 75 * data.labels.length,
                            md: 85 * data.labels.length,
                            lg: 95 * data.labels.length,
                          }}
                        >
                          <Bar
                            options={{
                              maintainAspectRatio: false,
                              legend: { align: "start" },
                              scales: {
                                yAxes: [
                                  {
                                    display: true,
                                    ticks: {
                                      suggestedMin: 0,
                                      beginAtZero: true,
                                    },
                                  },
                                ],
                              },
                            }}
                            data={chartJsData}
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <InputGroup mb="2rem">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<SearchIcon color="gray.300" />}
                        />
                        <Input
                          variant="filled"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          value={searchQuery}
                          placeholder="Search name"
                          maxW="20rem"
                        />
                      </InputGroup>
                      <Accordion>
                        {pollData.length > 0 ? (
                          renderSearchData()
                        ) : (
                          <Text>No people have answered this poll yet.</Text>
                        )}
                      </Accordion>
                    </TabPanel>
                    <TabPanel>
                      <Accordion>{renderListData()}</Accordion>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </GridItem>
            </Grid>
          </Grid>
        </ScaleFade>
      )}
    </div>
  );
};
