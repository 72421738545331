import React from 'react'
import {
  useHistory
} from 'react-router-dom'
import {
  Center,
  Box,
  Heading,
  Text,
  Button,
  Fade
} from '@chakra-ui/react'

const Landing = () => {
  const history = useHistory()

  return (
    <Box className='Landing'>
      <Fade in={true}>
        <Center px='2rem' w='100%' h='100vh'>
          <Box textAlign='center'>
            <Heading mb={1} fontSize='5xl'>Harmonize</Heading>
            <Text fontSize='xl'>Find times to meet with people</Text>
            <Button onClick={() => history.push('/create-poll')} mt={8} colorScheme='purple'>Create Poll</Button>
          </Box>
        </Center>
      </Fade>
    </Box>
  )
}

export { Landing }
