import React from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Landing } from './Landing/Landing'
import { CreatePoll } from './CreatePoll/CreatePoll'
import AnswerPoll from './AnswerPoll/AnswerPoll'
import ViewPoll from './ViewPoll/ViewPoll';
import ThankYou from './ThankYou'
function App () {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route path='/create-poll' component={CreatePoll} />
          <Route exact path='/' component={Landing} />
          <Route path='/answer/:answerPollId' component={AnswerPoll} />
          <Route path='/view/:pollId' component={ViewPoll} />
          <Route path='/thank-you' component={ThankYou} />
          {/* Redirect to Landing page on invalid route, could be 404 in future */}
          <Redirect to='/' />
        </Switch>
      </div>
    </Router>
  )
}

export default App
