import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { Fonts } from './ChakraComponents/Fonts'

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac'
  }
}

const fonts = {
  heading: 'Inter',
  body: 'Inter',
  button: 'Inter',
  input: 'Inter'
}

const theme = extendTheme({ colors, fonts })

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <Fonts />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
)
