function constructTimeArray() {
  const times = [];

  /* Please forgive me for this hacky solution */

  let time = 0;
  let suffix = "am";
  /* 1440 = number of minutes in a day */
  for (time; time < 1440; time = time + 30) {
    let lhs;
    let rhs;
    if (time >= 720) {
      suffix = "pm"
    }

    let isPM = time >= 780;
    if (time === 0 || time === 30) {
      lhs = "12"
    } else if (isPM) {
      lhs = Math.floor((time - 720) / 60);
    } else{
      lhs = Math.floor(time / 60);
    }

    rhs = (time % 60) === 0 ? "00" : "30"

    times.push(`${lhs}:${rhs}${suffix}`)
  }

  console.log(times);

  return times;
}

constructTimeArray();
class Time {
  /* Internal representation of time */
  static TIME_24H = constructTimeArray();

  /**
   * Return a array of Time objects
   *
   * @returns {Time}
   */
  static getTime24h = () => {
    return this.TIME_24H.map((time) => {
      return new Time(time);
    });
  };

  /**
   * Get times greater than a passed in Time object.
   * @param {Time} time Time object
   */
  static getTimesGreaterThan = (time) => {
    console.log(time);
    return Time.TIME_24H.slice(time.index + 1).map((timeString) => {
      return new Time(timeString);
    });
  };

  static getTimeAtIndex = (index) => {
    if (index < 0 || index > Time.TIME_24H.length) {
      throw Error("Invalid integer value passed in.");
    }

    return new Time(Time.TIME_24H[index]);
  };

  static getTimesBetweenTimeRange = (startTime, endTime) => {
    return this.TIME_24H.slice(startTime.index, endTime.index + 1);
  }


  /* Initial values */
  index = 0;
  string = Time.TIME_24H[0];

  constructor(value) {
    if (Number.isInteger(value)) {
      if (value < 0 || value > Time.TIME_24H.length) {
        throw Error("Invalid integer value passed in.");
      }

      this.index = value;
      this.string = Time.TIME_24H[value];
    }

    if (typeof value === "string") {
      const index = Time.TIME_24H.indexOf(value);
      if (index < 0) {
        throw Error("Invalid string value passed in.");
      }

      this.index = index;
      this.string = value;
    }
  }

  toString = () => {
    return this.string;
  };
}

export { Time };
